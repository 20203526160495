import Vue from "vue"
import VueRouter from "vue-router"
import configs from "@/middleware/configs";
Vue.use(VueRouter)

const authRequired = (from, to, next) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
	if (usuario) {

        if (usuario.menu) {

            let a = from.path.split('/')[1]
            if (!usuario.menu[a]) next('/usuario/auth')
            else {
                next({ name: usuario.menu[a] })
            }

        } else next('/usuario/auth')

    } else next('/usuario/auth')
}

const routes = [
	{
        path: '/',
        beforeEnter: authRequired,
        component: () => import('./components/index.vue'),
        children: [
            {
                beforeEnter: authRequired,
                name: 'agendamento',
                path: '/agendamento',
                redirect: '/agendamento/salvar/0/',
                component: () => import('./components/Agendamento/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Agendamento/Listar.vue') },
                    { name: 'agendamento/painel-telemedicina', path: 'painel-telemedicina', component: () => import('./components/Agendamento/PainelTelemedicina.vue') },
                    { name: 'agendamento/integracao', path: 'integracao', component: () => import('./components/Agendamento/Integracao.vue') },
                    { name: 'agendamento/salvar', path: 'salvar/:id/', component: () => import('./components/Agendamento/Salvar.vue') },
                    { path: 'calendario', component: () => import('./components/Agendamento/Calendario.vue') }
                ]
            },
          {
              beforeEnter: authRequired,
              name: 'agendamento-lista',
              path: '/agendamento-lista',
              redirect: '/agendamento-lista/listar/',
              component: () => import('./components/Agendamento/index.vue'),
              children: [
                { path: 'listar', component: () => import('./components/Agendamento/Listar.vue') }
              ]
            },
            {
                beforeEnter: authRequired,
                name: 'agenda',
                path: '/agenda',
                redirect: '/agenda/listar',
                component: () => import('./components/Agenda/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Agenda/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/Agenda/Visualizar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Agenda/Salvar.vue') },
                    { path: 'listar-bloqueios/:id/', component: () => import('./components/Agenda/ListarBloqueios.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'funcionario',
                path: '/funcionario',
                redirect: '/funcionario/listar',
                component: () => import('./components/Funcionario/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Funcionario/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Funcionario/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'especialista',
                path: '/especialista',
                redirect: '/especialista/listar',
                component: () => import('./components/Especialista/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Especialista/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/Especialista/Visualizar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Especialista/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'paciente',
                path: '/paciente',
                redirect: '/paciente/listar',
                component: () => import('./components/Paciente/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Paciente/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Paciente/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'agente-medclub',
                path: '/agente-medclub',
                redirect: '/agente-medclub/listar',
                component: () => import('./components/AgenteMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/AgenteMedclub/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Paciente/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'relatorio',
                path: '/relatorio',
                redirect: '/relatorio/listar',
                component: () => import('./components/Relatorio/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Relatorio/Listar.vue') },
                    { path: 'gerar', component: () => import('./components/Relatorio/Gerar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'relatorios-atendimento',
                path: '/relatorios-atendimento',
                redirect: '/relatorios-atendimento/listar',
                component: () => import('./components/Relatorio/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Relatorio/Listar.vue') },
                    { path: 'gerar', component: () => import('./components/Relatorio/Gerar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'atendimento-medico',
                path: '/atendimento-medico',
                redirect: '/atendimento-medico/listar',
                component: () => import('./components/AtendimentoMedico/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/AtendimentoMedico/Listar.vue') },
                    { name: 'em-atendimento', path: 'atendimento/:id/', component: () => import('./components/AtendimentoMedico/Atendimento.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'relatorio-medico',
                path: '/relatorio-medico',
                redirect: '/relatorio-medico/consultar',
                component: () => import('./components/RelatorioMedico/index.vue'),
                children: [
                    { path: 'consultar', component: () => import('./components/RelatorioMedico/Consultar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'caixa',
                path: '/caixa',
                redirect: '/caixa/listar-guias',
                component: () => import('./components/Caixa/index.vue'),
                children: [
                    { path: 'listar-guias', component: () => import('./components/Caixa/ListarGuias.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'gestao-pedidos',
                path: '/gestao-pedidos',
                redirect: '/gestao-pedidos/listar',
                component: () => import('./components/GestaoPedidos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/GestaoPedidos/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'estorno',
                path: '/estorno',
                redirect: '/estorno/listar',
                component: () => import('./components/Estorno/index.vue'),
                children: [
                    { name:"estorno-listar", path: 'listar', component: () => import('./components/Estorno/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'motivo-estorno',
                path: '/motivo-estorno',
                redirect: '/motivo-estorno/listar',
                component: () => import('./components/Estorno/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Estorno/ListarMotivos.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'troco',
                path: '/troco',
                redirect: '/troco/listar',
                component: () => import('./components/Troco/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Troco/ListarTrocos.vue') },
                    { path: 'salvar/:id', component: () => import('./components/Troco/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'resgate-creditos',
                path: '/resgate-creditos',
                redirect: '/resgate-creditos/listar',
                component: () => import('./components/Resgate/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Resgate/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'transacoes-financeiras',
                path: '/transacoes-financeiras',
                redirect: '/transacoes-financeiras/listar',
                component: () => import('./components/TransacoesFinanceiras/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/TransacoesFinanceiras/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/TransacoesFinanceiras/Visualizar.vue') },
                    { path: 'estornar/:id/', component: () => import('./components/TransacoesFinanceiras/Estornar.vue') },
                    { path: 'listar-guias/:id/', component: () => import('./components/TransacoesFinanceiras/ListarGuias.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'transacoes-financeiras-operacional',
                path: '/transacoes-financeiras-operacional',
                redirect: '/transacoes-financeiras-operacional/listar',
                component: () => import('./components/TransacoesFinanceiras/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/TransacoesFinanceiras/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/TransacoesFinanceiras/Visualizar.vue') },
                    { path: 'estornar/:id/', component: () => import('./components/TransacoesFinanceiras/Estornar.vue') },
                    { path: 'listar-guias/:id/', component: () => import('./components/TransacoesFinanceiras/ListarGuias.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'transacoes-cartoes',
                path: '/transacoes-cartoes',
                redirect: '/transacoes-cartoes/listar',
                component: () => import('./components/TransacoesCartoes/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/TransacoesCartoes/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'debitos-creditos',
                path: '/debitos-creditos',
                redirect: '/debitos-creditos/listar',
                component: () => import('./components/DebitosCreditos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/DebitosCreditos/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'creditos',
                path: '/creditos',
                redirect: '/creditos/listar',
                component: () => import('./components/Creditos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Creditos/Consulta.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'movimentos-caixa',
                path: '/movimentos-caixa',
                redirect: '/movimentos-caixa/listar',
                component: () => import('./components/MovimentoCaixa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/MovimentoCaixa/Listar.vue') }
                    /*{ path: 'visualizar/:id/', component: () => import('./components/TransacoesFinanceiras/Visualizar.vue') }*/
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'caixas',
                path: '/caixas',
                redirect: '/caixas/listar',
                component: () => import('./components/Caixas/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Caixas/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Caixas/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'caixa-geral',
                path: '/caixa-geral',
                redirect: '/caixa-geral/listar',
                component: () => import('./components/CaixaGeral/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/CaixaGeral/Listar.vue') },
                    //{ path: 'salvar/:id/', component: () => import('./components/CaixaGeral/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'formas-pagamento',
                path: '/formas-pagamento',
                redirect: '/formas-pagamento/salvar',
                component: () => import('./components/Caixas/index.vue'),
                children: [
                    { path: 'salvar', component: () => import('./components/Caixas/FormasPagamento.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'unidades-medclub',
                path: '/unidades-medclub',
                redirect: '/unidades-medclub/listar',
                component: () => import('./components/UnidadesMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/UnidadesMedclub/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/UnidadesMedclub/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'area-atuacao',
                path: '/area-atuacao',
                redirect: '/area-atuacao/listar',
                component: () => import('./components/AreaAtuacao/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/AreaAtuacao/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/AreaAtuacao/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'origem-atendimento',
                path: '/origem-atendimento',
                redirect: '/origem-atendimento/listar',
                component: () => import('./components/OrigemAtendimento/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/OrigemAtendimento/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'operadora',
                path: '/operadora',
                redirect: '/operadora/listar',
                component: () => import('./components/Operadora/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Operadora/Listar.vue') },

                    { path: 'salvar/:id', component: () => import('./components/Operadora/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'procedimentos-credenciado',
                path: '/procedimentos-credenciado',
                redirect: '/procedimentos-credenciado/listar',
                component: () => import('./components/ProcedimentosCredenciado/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/ProcedimentosCredenciado/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'procedimentos-estabelecimento',
                path: '/procedimentos-estabelecimento',
                redirect: '/procedimentos-estabelecimento/listar',
                component: () => import('./components/ProcedimentosEstabelecimento/index.vue'),
                children: [
                    { name: "pe-listar", path: 'listar', component: () => import('./components/ProcedimentosEstabelecimento/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/ProcedimentosEstabelecimento/Salvar.vue') }
                ]
            },
            {
            name: "subgrupo",
                path: "/subgrupo",
                redirect: "/subgrupo/listar",
                component: () => import("./components/Subgrupo/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () =>
                            import("./components/Subgrupo/Listar.vue")
                    },
                    {
                        path: "salvar/:id/",
                        component: () =>
                            import("./components/Subgrupo/Salvar.vue")
                    }
                ]
            },
            {
                name: "grupo-estabelecimentos",
                    path: "/grupo-estabelecimentos",
                    redirect: "/grupo-estabelecimentos/listar",
                    component: () => import("./components/GrupoEstabelecimentos/index.vue"),
                    children: [
                        {
                            path: "listar",
                            component: () =>
                                import("./components/GrupoEstabelecimentos/Listar.vue")
                        },
                    ]
                },
            {
                beforeEnter: authRequired,
                name: 'menus',
                path: '/menus',
                redirect: '/menus/listar',
                component: () => import('./components/Menus/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Menus/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Menus/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'endpoints',
                path: '/endpoints',
                redirect: '/endpoints/listar',
                component: () => import('./components/Endpoints/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Endpoints/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Endpoints/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'grupos',
                path: '/grupos',
                redirect: '/grupos/listar',
                component: () => import('./components/Grupos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Grupos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Grupos/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'mapa-grupos',
                path: '/mapa-grupos',
                redirect: '/mapa-grupos',
                component: () => import('./components/Grupos/index.vue'),
                children: [
                    { path: '', component: () => import('./components/Grupos/MapaGrupos.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'procedimentos',
                path: '/procedimentos',
                redirect: '/procedimentos/listar',
                component: () => import('./components/Procedimentos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Procedimentos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Procedimentos/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'cid',
                path: '/cid',
                redirect: '/cid/listar',
                component: () => import('./components/CID/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/CID/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Procedimentos/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'fechamento-caixa',
                path: '/fechamento-caixa',
                redirect: '/fechamento-caixa/listar',
                component: () => import('./components/FechamentoCaixa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/FechamentoCaixa/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/FechamentoCaixa/Visualizar.vue') },
                    { name: 'visualizar-supervisao', path: 'visualizar-supervisao/:id/', component: () => import('./components/FechamentoCaixa/VisualizarSupervisao.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'historico-operacional',
                path: '/historico-operacional',
                redirect: '/historico-operacional/listar',
                component: () => import('./components/FechamentoCaixa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/FechamentoCaixa/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/FechamentoCaixa/Visualizar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'guia-eletronica',
                path: '/guia-eletronica',
                redirect: '/guia-eletronica/validar',
                component: () => import('./components/GuiaEletronica/index.vue'),
                children: [
                    { path: 'validar', component: () => import('./components/GuiaEletronica/Validar.vue') },
                    { name: 'guia-eletronica/listar', path: 'listar', component: () => import('./components/GuiaEletronica/Listar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'protocolos',
                path: '/protocolos',
                redirect: '/protocolos/listar',
                component: () => import('./components/Protocolos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Protocolos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Protocolos/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'feriados',
                path: '/feriados',
                redirect: '/feriados/listar',
                component: () => import('./components/Feriados/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Feriados/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Feriados/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'estabelecimentos',
                path: '/estabelecimentos',
                redirect: '/estabelecimentos/listar',
                component: () => import('./components/Estabelecimento/index.vue'),
                children: [
                    { name:"estabelecimento-listar", path: 'listar', component: () => import('./components/Estabelecimento/Listar.vue') },
                    { name: "estabelecimento-salvar", path: 'salvar/:id/', component: () => import('./components/Estabelecimento/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'empresas',
                path: '/empresas',
                redirect: '/empresas/listar',
                component: () => import('./components/Empresa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Empresa/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Empresa/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'empresas-adm',
                path: '/empresas-adm',
                redirect: '/empresas-adm/listar',
                component: () => import('./components/Empresa/index.vue'),
                children: [
                    { name: 'listarEmpresasAdm', path: 'listar', component: () => import('./components/Empresa/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Empresa/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'med-empresa-empresas',
                path: '/med-empresa-empresas',
                redirect: '/med-empresa-empresas/listar',
                component: () => import('./components/EmpresaMedEmpresa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/EmpresaMedEmpresa/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/EmpresaMedEmpresa/Visualizar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'med-empresa-empresas-adm',
                path: '/med-empresa-empresas-adm',
                redirect: '/med-empresa-empresas-adm/listar',
                component: () => import('./components/EmpresaMedEmpresa/index.vue'),
                children: [
                    { name: 'listarMedEmpresas', path: 'listar', component: () => import('./components/EmpresaMedEmpresa/Listar.vue') },
                    { path: 'visualizar/:id/', component: () => import('./components/EmpresaMedEmpresa/Visualizar.vue') },
                    { name: 'aportes/:id/', path: 'aportes/:id/', component: () => import('./components/EmpresaMedEmpresa/Aportes.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'rendimentos',
                path: '/rendimentos',
                redirect: '/rendimentos/listar',
                component: () => import('./components/Rendimentos/index.vue'),
                children: [
                    { name: 'listar', path: 'listar', component: () => import('./components/Rendimentos/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'parceiro',
                path: '/parceiro',
                redirect: '/parceiro/listar',
                component: () => import('./components/Parceiro/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Parceiro/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Parceiro/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'nps',
                path: '/nps',
                redirect: '/nps/perguntas/listar',
                component: () => import('./components/Nps/index.vue'),
                children: [
                    { path: 'perguntas/listar', component: () => import('./components/Nps/Listar.vue') },
                    { path: 'perguntas/salvar/:id/', component: () => import('./components/Nps/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'avaliacoes',
                path: '/avaliacoes',
                redirect: '/avaliacoes/listar',
                component: () => import('./components/Avaliacoes/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Avaliacoes/Listar.vue') },]
            },
            {
                beforeEnter: authRequired,
                name: 'grupo-procedimentos',
                path: '/grupo-procedimentos',
                redirect: '/grupo-procedimentos/listar',
                component: () => import('./components/GrupoProcedimentos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/GrupoProcedimentos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/GrupoProcedimentos/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'cirurgias',
                path: '/cirurgias',
                redirect: '/cirurgias/listar',
                component: () => import('./components/Cirurgia/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Cirurgia/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Cirurgia/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'pacotes',
                path: '/pacotes',
                redirect: '/pacotes/listar',
                component: () => import('./components/Pacotes/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Pacotes/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Pacotes/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'orcamentos',
                path: '/orcamentos',
                redirect: '/orcamentos/listar',
                component: () => import('./components/Orcamentos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Orcamentos/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'orcamentos-clinica',
                path: '/orcamentos-clinica',
                redirect: '/orcamentos-clinica/listar',
                component: () => import('./components/Orcamentos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Orcamentos/ListarValidados.vue') },
                    { name: 'orcamentos-clinica/validar', path: 'validar', component: () => import('./components/Orcamentos/Validar.vue') },
                    { name: 'orcamentos-clinica/visualizar', path: 'visualizar/:id/', component: () => import('./components/Orcamentos/Visualizar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'lotes',
                path: '/lotes',
                redirect: '/lotes/listar-guias/0',
                component: () => import('./components/Lotes/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Lotes/ListarLotes.vue') },
                    { path: 'listar-guias/:id/', component: () => import('./components/Lotes/ListarGuias.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Lotes/ListarGuiasFinalizar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'lotes-list',
                path: '/lotes-list',
                redirect: '/lotes-list/listar',
                component: () => import('./components/LotesList/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/LotesList/ListarLotes.vue') },
                ]
            },            {
                beforeEnter: authRequired,
                name: 'faturamento',
                path: '/faturamento',
                redirect: '/faturamento/listar',
                component: () => import('./components/Faturamento/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Faturamento/ListarPagamentos.vue') },
                    { path: 'listar-guias/:id/', component: () => import('./components/Faturamento/ListarGuias.vue') },
                    { path: 'faturar/:id/', component: () => import('./components/Faturamento/Faturar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'preco-procedimentos',
                path: '/preco-procedimentos',
                redirect: '/preco-procedimentos/listar',
                component: () => import('./components/PrecoProcedimentos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/PrecoProcedimentos/ListarPrecoProcedimentos.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'tipo-checkup',
                path: '/tipo-checkup',
                redirect: '/tipo-checkup/listar',
                component: () => import('./components/TipoCheckup/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/TipoCheckup/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/TipoCheckup/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'checkup',
                path: '/checkup',
                redirect: '/checkup/listar',
                component: () => import('./components/Checkup/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Checkup/Listar.vue') },
                  //  { name: 'checkup/compras', path: 'compras', component: () => import('./components/Checkup/ListarCompras.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Checkup/Salvar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'revendedor',
                path: '/revendedor',
                redirect: '/revendedor/listar',
                component: () => import('./components/RevendedorMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/RevendedorMedclub/Agenda.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/RevendedorMedclub/Salvar.vue') },
                    { path: 'agendar/:id/', component: () => import('./components/RevendedorMedclub/Agendar.vue') },
                    { path: 'ver-precos', component: () => import('./components/PrecoProcedimentos/ListarPrecoProcedimentos.vue') },
                    { path: 'ver-calendario', component: () => import('./components/Agendamento/Calendario.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'revendedores',
                path: '/revendedores',
                redirect: '/revendedores/listar',
                component: () => import('./components/RevendedorMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/RevendedorMedclub/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'clientes-revenda',
                path: '/clientes-revenda',
                redirect: '/clientes-revenda/listar',
                component: () => import('./components/RevendedorMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/RevendedorMedclub/EmpresaRevendedor.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'comprar-creditos',
                path: '/comprar-creditos',
                redirect: '/comprar-creditos/listar',
                component: () => import('./components/RevendedorMedclub/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/RevendedorMedclub/Recarregar.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'checkup-compras',
                path: '/checkup-compras',
                redirect: '/checkup-compras/listar',
                component: () => import('./components/Checkup/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Checkup/ListarCompras.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'perguntas-frequentes',
                path: '/perguntas-frequentes',
                redirect: '/perguntas-frequentes/listar',
                component: () => import('./components/PerguntasFrequentes/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/PerguntasFrequentes/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/PerguntasFrequentes/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/PerguntasFrequentes/Salvar.vue') },
                    { path: 'categorias', component: () => import('./components/PerguntasFrequentes/ListarCategorias.vue') }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'noticias',
                path: '/noticias',
                redirect: '/noticias/listar',
                component: () => import('./components/Noticias/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Noticias/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Noticias/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Noticias/Salvar.vue') },
                    { path: 'editar-categorias', component: () => import('./components/Noticias/EditarCategorias.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'contas-receber',
                path: '/contas-receber',
                redirect: '/contas-receber/listar',
                component: () => import('./components/ContasReceber/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/ContasReceber/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/ContasReceber/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/ContasReceber/Salvar.vue') },
                ]
            },

            {
                beforeEnter: authRequired,
                name: "contas",
                path: "/contas",
                redirect: "/contas/listar",
                component: () =>
                    import("./components/ContasBancarias/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () =>
                            import("./components/ContasBancarias/Listar.vue")
                    },
                    {
                        path: "salvar/:id/",
                        component: () =>
                            import("./components/ContasBancarias/Salvar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: "agencias",
                path: "/agencias",
                redirect: "/agencias/listar",
                component: () =>
                    import("./components/AgenciasBancarias/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () =>
                            import("./components/AgenciasBancarias/Listar.vue")
                    },
                    {
                        path: "salvar/:id/",
                        component: () =>
                            import("./components/AgenciasBancarias/Salvar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'anexos',
                path: '/anexos',
                redirect: '/anexos/listar',
                component: () => import('./components/Anexos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Anexos/Listar.vue') },
                    { path: 'editar-tipos', component: () => import('./components/Anexos/EditarTipos.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'albuns',
                path: '/albuns',
                redirect: '/albuns/listar',
                component: () => import('./components/Albuns/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Albuns/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Albuns/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Albuns/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'banners',
                path: '/banners',
                redirect: '/banners/listar',
                component: () => import('./components/Banners/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Banners/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Banners/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Banners/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: "mapa-ocupacao",
                path: "/mapa-ocupacao",
                redirect: "/mapa-ocupacao/listar",
                component: () => import("./components/MapaOcupacao/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () => import("./components/MapaOcupacao/Listar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: "locais-atendimento",
                path: "/locais-atendimento",
                redirect: "/locais-atendimento/listar",
                component: () => import("./components/LocaisAtendimento/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () => import("./components/LocaisAtendimento/Listar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: "locais-med-empresa",
                path: "/locais-med-empresa",
                redirect: "/locais-med-empresa/listar",
                component: () => import("./components/LocaisAtendimento/index.vue"),
                children: [
                    {
                        name: "locais_med_empresa",
                        path: "listar",
                        component: () => import("./components/LocaisAtendimento/Listar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'med-empresa',
                path: '/med-empresa',
                redirect: '/med-empresa/atendimentos',
                component: () => import('./components/MedEmpresa/index.vue'),
                children: [
                    { path: 'atendimentos', component: () => import('./components/MedEmpresa/Listar.vue') },
                    { name: 'atendimento_med_empresa', path: 'atendimento/:id', component: () => import('./components/Agendamento/Salvar.vue') },
                    // { name: 'locais_med_empresa', path: 'locais-atendimento', component: () => import('./components/LocaisAtendimento/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'empresa-contas-pagar',
                path: '/empresa-contas-pagar',
                redirect: '/empresa-contas-pagar/listar',
                component: () => import('./components/ContasAPagarEmpresa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/ContasAPagarEmpresa/Listar.vue') },
                    // { path: 'visualizar/:id/', component: () => import('./components/ContasAPagarEmpresa/Visualizar.vue') },
                    // { path: 'visualizar', component: () => import('./components/ContasAPagarEmpresa/Visualizar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'gestao-contrato',
                path: '/gestao-contrato',
                redirect: '/gestao-contrato/listar',
                component: () => import('./components/GestaoContratoEmpresa/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/GestaoContratoEmpresa/Listar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'rede-credenciada',
                path: '/rede-credenciada',
                redirect: '/rede-credenciada/estabelecimentos',
                component: () => import('./components/RedeCredenciada/index.vue'),
                children: [
                    { path: 'estabelecimentos', component: () => import('./components/RedeCredenciada/estabelecimentos.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: "comunicacao-interna",
                path: "/comunicacao-interna",
                redirect: "/comunicacao-interna/listar",
                component: () => import("./components/ComunicacaoInterna/index.vue"),
                children: [
                    {
                        path: "listar",
                        component: () => import("./components/ComunicacaoInterna/Listar.vue")
                    }
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'setor',
                path: '/setor',
                redirect: '/setor/listar',
                component: () => import('./components/Setor/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Setor/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Setor/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Setor/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'sala',
                path: '/sala',
                redirect: '/sala/listar',
                component: () => import('./components/Sala/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Sala/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Sala/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Sala/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'plano-de-contas',
                path: '/plano-de-contas',
                redirect: '/plano-de-contas/listar',
                component: () => import('./components/PlanoContas/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/PlanoContas/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/PlanoContas/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/PlanoContas/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'dominios',
                path: '/dominios',
                redirect: '/dominios/listar',
                component: () => import('./components/Dominios/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Dominios/Listar.vue') },
                    { path: 'salvar/:ds_mnemonico/', component: () => import('./components/Dominios/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Dominios/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'pessoa-juridica',
                path: '/pessoa-juridica',
                redirect: '/pessoa-juridica/listar',
                component: () => import('./components/PessoaJuridica/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/PessoaJuridica/Listar.vue') },
                    { path: 'salvar/:id', component: () => import('./components/PessoaJuridica/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/PessoaJuridica/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'centro-lucro',
                path: '/centro-lucro',
                redirect: '/centro-lucro/listar',
                component: () => import('./components/CentroLucro/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/CentroLucro/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/CentroLucro/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/CentroLucro/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'bancos',
                path: '/bancos',
                redirect: '/bancos/listar',
                component: () => import('./components/Bancos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Bancos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Bancos/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Bancos/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'transferencias',
                path: '/transferencias',
                redirect: '/transferencias/listar',
                component: () => import('./components/Transferencias/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/Transferencias/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/Transferencias/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/Transferencias/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'historico-integracao',
                path: '/historico-integracao',
                redirect: '/historico-integracao/listar',
                component: () => import('./components/HistoricoIntegracao/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/HistoricoIntegracao/Listar.vue') },
                    { path: 'listar/:id/', component: () => import('./components/HistoricoIntegracao/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'servicos-externos',
                path: '/servicos-externos',
                redirect: '/servicos-externos/listar',
                component: () => import('./components/ServicosExternos/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/ServicosExternos/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/ServicosExternos/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/ServicosExternos/Salvar.vue') },
                ]
            },
            {
                beforeEnter: authRequired,
                name: 'debito-credito-negociado',
                path: '/debito-credito-negociado',
                redirect: '/debito-credito-negociado/listar',
                component: () => import('./components/DebitoCreditoNegociado/index.vue'),
                children: [
                    { path: 'listar', component: () => import('./components/DebitoCreditoNegociado/Listar.vue') },
                    { path: 'salvar/:id/', component: () => import('./components/DebitoCreditoNegociado/Salvar.vue') },
                    { path: 'salvar', component: () => import('./components/DebitoCreditoNegociado/Salvar.vue') },
                ]
            },
            // {
            //     beforeEnter: authRequired,
            //     name: 'prontuarios',
            //     path: '/prontuarios',
            //     redirect: '/prontuarios/listar',
            //     component: () => import('./components/Prontuários/index.vue'),
            //     children: [
            //         { path: 'listar', component: () => import('./components/Prontuários/Listar.vue') },
            //     ]
            // },
        ]
	},
    {
        name: 'usuario',
        path: '/usuario',
        component: () => import('./components/index.vue'),
        children: [
            { path: 'erro', component: () => import('./components/Usuario/Error.vue') },
            {
                path: 'auth',
                redirect: 'auth/login',
                component: () => import('./components/Usuario/Auth.vue'),
                children: [
                    { path: 'login', component: () => import('./components/Usuario/Login.vue') },
                    { path: 'recuperar-senha', component: () => import('./components/Usuario/RecuperarSenha.vue') }
                ]
            }
        ]
    },
    {
        path: '*',
        redirect: '/usuario/erro'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: configs.basePath || process.env.BASE_URL,
    routes
})

export default router
